.form-check-input {
  &:checked {
    &:focus {
      border-color: $form-check-input-focus-border;
    }
  }

  &[disabled],
  &:disabled {
    background-color: $form-check-input-disabled-bg;
    border-color: $form-check-input-disabled-border-color;

    &:checked {
      &[type="radio"] {
        background-image: escape-svg(
          $form-check-radio-checked-bg-image-disabled
        );
      }

      &[type="checkbox"] {
        background-image: escape-svg(
          $form-check-radio-checked-bg-image-disabled
        );
      }
    }

    ~ .form-check-label {
      color: $neutral-80;
    }
  }
}

@each $name, $width in $form-check-input-widths {
  .form-check-input-#{$name} {
    width: $width;
    height: $width;
  }
}

.btn-check {
  &[disabled],
  &:disabled {
    + .btn {
      --#{$prefix}btn-bg: #{$neutral-20};
      --#{$prefix}btn-color: #{$neutral-70};
      --#{$prefix}btn-border-color: #{$neutral-20};
    }
  }
}
