.tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 4px;
  border-radius: $border-radius;
  color: $neutral-800;
  background-color: $neutral-20;

  &.tag-removable {
    padding-right: 0;
  }
}

.tag-text {
  max-width: 160px;
  padding: 2px 0;
  min-height: 1.25rem;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-removable {
  .btn-close {
    margin-right: 4px;
    margin-left: 4px;
  }
}
