.dropdown {
  display: inline-flex;
  vertical-align: top;

  &.is-hoverable {
    &:hover {
      .dropdown-menu {
        display: block;
        top: 100%;
      }
    }
  }
}

.dropdown-menu {
  max-width: $dropdown-max-width;
  box-shadow: $dropdown-box-shadow;
}

.dropdown-description {
  display: block;
  margin-top: 3px;
  font-size: 12px;
  color: $neutral-200;
  line-height: 1;
  word-break: break-word;
}

.dropdown-header {
  font-weight: 500;
}
