.nav-tabs {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background-color: $neutral-30;
  }

  .nav-link {
    position: relative;
    font-size: 0.875rem;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      border-radius: 2px;
      @include transition($nav-link-transition);
    }

    &.active,
    .nav-item.show & {
      &::after {
        background-color: $nav-tabs-link-active-color;
      }
    }
  }
}

.nav-tabs-vertical {
  flex-direction: column;

  &::before {
    width: 2px;
    height: 100%;
    right: 0;
    left: auto;
  }

  .nav-link {
    padding: $nav-link-padding-x $nav-link-padding-y;
    text-align: center;
    &::after {
      width: 2px;
      height: 100%;
      right: 0;
      left: auto;
    }
  }
}
