.form-select {
  &[multiple] {
    border-color: $form-select-hover-bg;
  }

  &:hover {
    border-color: $form-select-hover-border-color;
    background-color: $form-select-hover-bg;
  }

  &:focus {
    background-color: $neutral-0;
  }

  &:focus-visible {
    border-color: $form-select-focus-border-color;
    background-color: $neutral-0;
  }

  &:disabled {
    background-image: escape-svg($form-select-disabled-indicator);

    &:hover {
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }
  }
}
