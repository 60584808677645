.sidenav {
  --sidenav-width: 256px;

  position: relative;
  width: var(--sidenav-width);
  height: 100%;
  display: none;
  flex-direction: column;
  background-color: $neutral-0;
  outline: 0;
  overflow: auto;
  z-index: $zindex-sidenav;

  &.showing,
  &.hiding,
  &.show {
    display: flex;
    visibility: visible;
  }
}

.sidenav-body{
  padding: $sidenav-padding-y $sidenav-padding-x;
}

.sidenav-wide {
  --sidenav-width: 296px;
}

.sidenav-narrow {
  --sidenav-width: 240px;
}

.sidenav-footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: $sidenav-padding-y $sidenav-padding-x;
}

.sidenav-backdrop {
  @include overlay-backdrop(
    $zindex-sidenav-backdrop,
    $sidenav-backdrop-bg,
    $sidenav-backdrop-opacity
  );
}