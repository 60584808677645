.avatar {
  position: relative;
  width: $avatar-width;
  height: $avatar-width;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $avatar-bg;
  color: $avatar-color;
  line-height: 1;
  font-size: 14px;
  border-radius: 50%;
  object-fit: cover;

  & > img,
  & > svg {
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.avatar-stack {
  display: flex;
  flex-direction: row;
}

.avatar-stack-reverse {
  flex-direction: row-reverse;
  margin-left: 0;

  .avatar-item {
    margin-left: 0;
    margin-right: -8px;
  }
}

.avatar-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 50%;
  outline: 0;
  padding: 0;
  box-sizing: content-box;
  border: 2px solid $white;
  overflow: hidden;
  margin-left: -8px;

  &:first-child {
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    inset: 0;
    background-color: transparent;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  &:hover {
    text-decoration: none;
  }
}

.avatar-badge {
  position: absolute;
  height: 8px;
  width: 8px;
  bottom: 14%;
  right: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 1;
  background-color: $neutral-200;
  color: $white;
  transform: translate(50%, 50%);
  overflow: hidden;
  z-index: 1;
}

@each $name, $width in $avatar-widths {
  .avatar-#{$name} {
    width: $width;
    height: $width;
  }
}
