.form-range {
  --track-bg: #{$form-range-track-bg};
  --track-fg: #{$blue-400};

  &::-moz-range-track {
    background-color: var(--track-bg);
    transition: $form-range-thumb-transition;
  }

  &::-moz-range-thumb {
    box-shadow: $form-range-thumb-box-shadow;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    background-color: var(--track-bg);
    transition: $form-range-thumb-transition;
  }

  &::-webkit-slider-thumb {
    box-shadow: $form-range-thumb-box-shadow;
    cursor: pointer;
  }

  &:focus {
    &::-moz-range-thumb {
      border-color: $blue-200;
      box-shadow: $form-range-thumb-focus-box-shadow;
    }
    &::-webkit-slider-thumb {
      border-color: $blue-200;
      box-shadow: $form-range-thumb-focus-box-shadow;
    }
  }

  &:hover {
    --track-bg: #{$neutral-40};
  }
}

.form-range-filling {
  &:disabled {
    --track-fg: #{$neutral-50};
  }

  &::-webkit-slider-runnable-track {
    background-image: linear-gradient(var(--track-fg), var(--track-fg));
    background-repeat: no-repeat;
    background-size: var(--track-fg-width, 0) 100%;
  }

  &::-moz-range-progress {
    height: $form-range-track-height;
    border-radius: $form-range-track-border-radius;
    transition: $form-range-thumb-transition;
    background-color: var(--track-fg);
    width: 100%;
    cursor: pointer;
  }
}
