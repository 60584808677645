.progress-tracker {
  --#{$prefix}progress-tracker-height: 8px;
  --#{$prefix}progress-tracker-width: 8px;
  --#{$prefix}progress-tracker-border-radius: 8px;

  position: relative;
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 0.875rem;
  overflow: hidden;
}

.progress-tracker-item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 20px;

  &.active {
    .progress-tracker-label {
      color: $blue-300;
      font-weight: 500;
    }

    &::after {
      background-color: $blue-300;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.completed {
    .progress-tracker-label {
      color: $neutral-800;
    }

    &::after {
      width: 100%;
      border-radius: 0;
      background-color: $blue-300;
    }
  }

  &.disabled {
    pointer-events: none;
    .progress-tracker-label {
      color: $neutral-70;
    }
  }

  &:first-child {
    &.active,
    &.completed {
      &::after {
        border-top-left-radius: var(--#{$prefix}progress-tracker-border-radius);
        border-bottom-left-radius: var(--#{$prefix}progress-tracker-border-radius);
      }
    }
  }

  &:last-child {
    &.active,
    &.completed {
      &::after {
        border-top-right-radius: var(--#{$prefix}progress-tracker-border-radius);
        border-bottom-right-radius: var(--#{$prefix}progress-tracker-border-radius);
      }
    }
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: var(--#{$prefix}progress-tracker-width);
    height: var(--#{$prefix}progress-tracker-height);
    border-radius: var(--#{$prefix}progress-tracker-border-radius);
    background-color: $neutral-70;
  }
}

.progress-tracker-label {
  color: $neutral-300;
  transition: color 0.2s ease;
}

.progress-tracker-sm {
  --#{$prefix}progress-tracker-height: 4px;
  --#{$prefix}progress-tracker-width: 4px;
  --#{$prefix}progress-tracker-border-radius: 4px;
}
