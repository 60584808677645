%btn-is-selected {
  --#{$prefix}btn-active-color: #{$neutral-0};
  --#{$prefix}btn-active-border-color: #{$neutral-700};
  --#{$prefix}btn-active-bg: #{$neutral-700};

  --#{$prefix}btn-hover-color: #{$neutral-0};
  --#{$prefix}btn-hover-bg: #{$neutral-700};
  --#{$prefix}btn-hover-border-color: #{$neutral-700};
}

.btn {
  --#{$prefix}btn-disabled-bg: #{$neutral-20};
  --#{$prefix}btn-disabled-color: #{$neutral-70};
  --#{$prefix}btn-disabled-border-color: #{$neutral-20};

  .btn-check:checked + &,
  &.show {
    @extend %btn-is-selected;
  }
}

.btn-default {
  --#{$prefix}btn-color: #{$neutral-500};
  --#{$prefix}btn-bg: #{$neutral-20};
  --#{$prefix}btn-border-color: #{$neutral-20};
  --#{$prefix}btn-hover-color: #{$neutral-500};
  --#{$prefix}btn-hover-bg: #{$neutral-30};
  --#{$prefix}btn-hover-border-color: #{$neutral-30};
  --#{$prefix}btn-active-color: #{$blue-400};
  --#{$prefix}btn-active-border-color: #{$blue-50};
  --#{$prefix}btn-active-bg: #{$blue-50};

  &.active {
    @extend %btn-is-selected;
  }
}

.btn-subtle {
  --#{$prefix}btn-color: #{$neutral-500};
  --#{$prefix}btn-bg: #{$neutral-0};
  --#{$prefix}btn-hover-color: #{$neutral-500};
  --#{$prefix}btn-border-color: #{$neutral-0};
  --#{$prefix}btn-hover-bg: #{$neutral-30};
  --#{$prefix}btn-hover-border-color: #{$neutral-30};
  --#{$prefix}btn-active-color: #{$blue-400};
  --#{$prefix}btn-active-border-color: #{$blue-50};
  --#{$prefix}btn-active-bg: #{$blue-50};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;

  &.active {
    @extend %btn-is-selected;
  }
}

.btn-primary {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-border-color: #{$blue-400};
  --#{$prefix}btn-hover-bg: #{$blue-300};
  --#{$prefix}btn-hover-border-color: #{$blue-300};
  --#{$prefix}btn-active-border-color: #{$blue-500};
  --#{$prefix}btn-active-bg: #{$blue-500};

  &.active {
    @extend %btn-is-selected;
  }
}

.btn-warning {
  --#{$prefix}btn-color: #{$neutral-800};
  --#{$prefix}btn-hover-color: #{$neutral-800};
  --#{$prefix}btn-border-color: #{$yellow-300};
  --#{$prefix}btn-hover-bg: #{$yellow-200};
  --#{$prefix}btn-hover-border-color: #{$yellow-200};
  --#{$prefix}btn-active-border-color: #{$yellow-400};
  --#{$prefix}btn-active-bg: #{$yellow-400};
}

.btn-danger {
  --#{$prefix}btn-color: #{$neutral-0};
  --#{$prefix}btn-hover-color: #{$neutral-0};
  --#{$prefix}btn-border-color: #{$red-400};
  --#{$prefix}btn-hover-bg: #{$red-300};
  --#{$prefix}btn-hover-border-color: #{$red-300};
  --#{$prefix}btn-active-border-color: #{$red-500};
  --#{$prefix}btn-active-bg: #{$red-500};
}

.btn-link {
  --#{$prefix}btn-active-color: #{$blue-500};
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;

  &.active {
    @extend %btn-is-selected;
  }

  &:hover,
  &:focus-visible {
    text-decoration: $link-hover-decoration !important;
  }
}

.btn-sm {
  --#{$prefix}btn-line-height: $btn-line-height-sm;
}

.btn-lg {
  --#{$prefix}btn-line-height: 28px;
}
