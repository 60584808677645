.blankslate {
  position: relative;
  max-width: 464px;
  margin: 50px auto;
  text-align: center;
  font-size: 0.875rem;

  p {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

.blankslate-heading {
  margin-bottom: 1rem;
  font-size: 1.42857em;
  line-height: 1.2;
  font-weight: $font-weight-semibold;
  letter-spacing: -0.008em;
}

.blankslate-img {
  max-width: 160px;
  max-height: 160px;
  display: block;
  margin: 0px auto 24px;
}

.blankslate-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-bottom: 1rem;

  & > * {
    margin-right: 8px;

    &:first-child {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.blankslate-narrow {
  max-width: 304px;
}
