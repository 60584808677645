.rating {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $yellow-200;
  cursor: pointer;

  &.disabled {
    opacity: 0.38;
    pointer-events: none;
  }

  input {
    display: none;
  }
}

.rating-item {
  position: relative;
  color: inherit;
  transition: transform 0.15s ease-in-out;
  cursor: pointer;
}

.rating-icon {
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  overflow: hidden;

  & ~ .rating-icon {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    
    .rating-item.active & {
      visibility: visible;
    }
  }
}
